import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../../components/Seo'
import AllBlogs from '../../components/AllBlogs'

const BlogPost = ({ data }) => {
  const { slug: blogSlug, featuredBlogPost } = data.contentfulBlog
  const {
    excerpt: { excerpt },
    seoImage,
    slug,
    title,
  } = data.contentfulBlogPost

  return (
    <>
      <Seo
        title={title}
        description={excerpt}
        pathname={`${blogSlug}/${slug}`}
        image={seoImage?.fixed.src}
      />
      <AllBlogs
        blogSlug={blogSlug}
        featuredBlogPost={featuredBlogPost}
        allContentfulBlogPost={data.allContentfulBlogPost}
      />
    </>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query AllBlogsQuery {
    contentfulBlog {
      slug
      featuredBlogPost {
        seoImage {
          gatsbyImageData(quality: 100)
        }
        date(formatString: "MMMM DD, YYYY")
        title
        titleIcon
        slug
      }
    }
    allContentfulBlogPost(sort: { fields: date, order: DESC }) {
      nodes {
        date(formatString: "MMMM DD, YYYY")
        title
        titleIcon
        excerpt {
          excerpt
        }
        slug
      }
    }
    contentfulBlogPost(slug: {}) {
      excerpt {
        excerpt
      }
      seoImage {
        fixed(height: 630, width: 1200) {
          src
        }
      }
      slug
      date(formatString: "MMMM DD, YYYY")
      title
      titleIcon
      content {
        raw
        references {
          ...ContentfulRichTextReferences
        }
      }
    }
  }
`
