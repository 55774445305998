import React from 'react'
import dateFormat from 'dateformat'

import ArticleCard from '../ArticleCard'
import {
  BlogPostsCards,
  BlogPostsCardsInner,
  BlogPostsDot,
  BlogPostsDotTwo,
  BlogPostsEmpty,
  BlogPostsMain,
} from '../BlogPosts/index.style'
import Container from '../Container'
import FeaturedBlogCard from '../FeaturedBlogCard'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import Dot from '../svgs/Dot'
import { TextBody } from '../TextStyles'

const AllBlogs = ({ blogSlug, featuredBlogPost, allContentfulBlogPost }) => {
  return (
    <BlogPostsMain>
      <BlogPostsDot>
        <Dot />
      </BlogPostsDot>

      <BlogPostsDotTwo>
        <Dot />
      </BlogPostsDotTwo>

      <Container>
        <Spacer size={[60, 180]} />
        <Grid>
          {!!featuredBlogPost && (
            <GridItem desk={14} deskStart={2}>
              <FeaturedBlogCard
                date={featuredBlogPost.date}
                title={featuredBlogPost.title}
                titleIcon={featuredBlogPost.titleIcon}
                to={`${blogSlug}/${featuredBlogPost.slug}/`}
                image={featuredBlogPost.seoImage}
              />
            </GridItem>
          )}

          <GridItem desk={14} deskStart={2}>
            <BlogPostsCards>
              <BlogPostsCardsInner>
                {allContentfulBlogPost.nodes.length ? (
                  <Grid columnCount={14}>
                    {Array.isArray(allContentfulBlogPost?.nodes) &&
                      React.Children.toArray(
                        allContentfulBlogPost.nodes.map(post => (
                          <GridItem tabletP={7}>
                            <ArticleCard
                              label={dateFormat(post.date, 'mmmm d, yyyy')}
                              title={post.title}
                              titleIcon={post.titleIcon}
                              excerpt={post.excerpt.excerpt}
                              to={`${'/blog'}/${post.slug}/`}
                            />
                          </GridItem>
                        ))
                      )}
                  </Grid>
                ) : (
                  <BlogPostsEmpty>
                    <Spacer size={[60, 180]} />

                    <Spacer size={[24, 30]} />

                    <TextBody maxWidth={30.73}>
                      Try visiting us at a later date! Don’t worry, we are
                      regularly adding new articles to stay up to date with the
                      industry.
                    </TextBody>

                    <Spacer size={[32, 50]} />

                    <Spacer size={[60, 180]} />
                  </BlogPostsEmpty>
                )}
              </BlogPostsCardsInner>
            </BlogPostsCards>
          </GridItem>
        </Grid>
      </Container>
    </BlogPostsMain>
  )
}

export default AllBlogs
